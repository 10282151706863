import { Paths, PERMISSIONS } from "@/constants"

import type { ItemType } from "antd/lib/menu/hooks/useItems"

export type MenuItem = ItemType & {
  path?: string
  permissionKey?: string[]
  parent?: string
  children?: MenuItem[]
}

export const commonItems: MenuItem[] = [
  {
    key: Paths.USER.LIST.PATH,
    label: Paths.USER.LIST.LABEL,
    permissionKey: [
      PERMISSIONS.USERS_CREATE,
      PERMISSIONS.USERS_DELETE,
      PERMISSIONS.USERS_READ,
      PERMISSIONS.USERS_UPDATE,
    ],
  },
  {
    key: Paths.ROLE.LIST.PATH,
    label: Paths.ROLE.LIST.LABEL,
    permissionKey: [PERMISSIONS.ROLE_CREATE, PERMISSIONS.ROLE_DELETE, PERMISSIONS.ROLE_READ, PERMISSIONS.ROLE_UPDATE],
  },
  {
    key: Paths.PERMISSION.LIST.PATH,
    label: Paths.PERMISSION.LIST.LABEL,
    permissionKey: [
      PERMISSIONS.PERMISSION_CREATE,
      PERMISSIONS.PERMISSION_DELETE,
      PERMISSIONS.PERMISSION_READ,
      PERMISSIONS.PERMISSION_UPDATE,
    ],
  },
]

export const hrItems: MenuItem[] = [
  {
    key: Paths.STAFF.LIST.PATH,
    label: Paths.STAFF.LIST.LABEL,
    permissionKey: [
      PERMISSIONS.STAFF_CREATE,
      PERMISSIONS.STAFF_DELETE,
      PERMISSIONS.STAFF_READ,
      PERMISSIONS.STAFF_UPDATE,
    ],
  },
  {
    key: Paths.CHECKIN.LIST.PATH,
    label: Paths.CHECKIN.LIST.LABEL,
    permissionKey: [
      PERMISSIONS.CHECK_IN_CREATE,
      PERMISSIONS.CHECK_IN_DELETE,
      PERMISSIONS.CHECK_IN_READ,
      PERMISSIONS.CHECK_IN_UPDATE,
    ],
  },
  {
    key: Paths.PAYSHEET.LIST.PATH,
    label: Paths.PAYSHEET.LIST.LABEL,
    permissionKey: [
      PERMISSIONS.PAYSHEETS_CREATE,
      PERMISSIONS.PAYSHEETS_DELETE,
      PERMISSIONS.PAYSHEETS_READ,
      PERMISSIONS.PAYSHEETS_UPDATE,
    ],
  },
  {
    key: Paths.INTERNALDEBTS.LIST.PATH,
    label: Paths.INTERNALDEBTS.LIST.LABEL,
    permissionKey: [
      PERMISSIONS.INTERNAL_DEBTS_CREATE,
      PERMISSIONS.INTERNAL_DEBTS_DELETE,
      PERMISSIONS.INTERNAL_DEBTS_READ,
      PERMISSIONS.INTERNAL_DEBTS_UPDATE,
    ],
  },
]

export const settingItems: MenuItem[] = [
  {
    key: Paths.CATEGORY.LIST.PATH,
    label: Paths.CATEGORY.LIST.LABEL,
    permissionKey: [
      PERMISSIONS.SETTINGS_CREATE,
      PERMISSIONS.SETTINGS_DELETE,
      PERMISSIONS.SETTINGS_READ,
      PERMISSIONS.SETTINGS_UPDATE,
    ],
  },
  {
    key: Paths.WORKDAYS.LIST.PATH,
    label: Paths.WORKDAYS.LIST.LABEL,
    permissionKey: [
      PERMISSIONS.SETTINGS_CREATE,
      PERMISSIONS.SETTINGS_DELETE,
      PERMISSIONS.SETTINGS_READ,
      PERMISSIONS.SETTINGS_UPDATE,
    ],
  },
]

export const financeItems: MenuItem[] = [
  {
    key: Paths.RECEIPT.LIST.PATH,
    label: Paths.RECEIPT.LIST.LABEL,
    permissionKey: [
      PERMISSIONS.RECEIPTS_CREATE,
      PERMISSIONS.RECEIPTS_DELETE,
      PERMISSIONS.RECEIPTS_READ,
      PERMISSIONS.RECEIPTS_UPDATE,
    ],
  },
  {
    key: Paths.PAYABLEDEBTS.LIST.PATH,
    label: Paths.PAYABLEDEBTS.LIST.LABEL,
    permissionKey: [
      PERMISSIONS.PAYABLE_DEBTS_CREATE,
      PERMISSIONS.PAYABLE_DEBTS_DELETE,
      PERMISSIONS.PAYABLE_DEBTS_READ,
      PERMISSIONS.PAYABLE_DEBTS_UPDATE,
    ],
  },
  {
    key: Paths.RECEIVABLEDEBTS.LIST.PATH,
    label: Paths.RECEIVABLEDEBTS.LIST.LABEL,
    permissionKey: [
      PERMISSIONS.RECEIVABLE_DEBTS_CREATE,
      PERMISSIONS.RECEIVABLE_DEBTS_DELETE,
      PERMISSIONS.RECEIVABLE_DEBTS_READ,
      PERMISSIONS.RECEIVABLE_DEBTS_UPDATE,
    ],
  },
  {
    key: Paths.CURRENCY.LIST.PATH,
    label: Paths.CURRENCY.LIST.LABEL,
    permissionKey: [PERMISSIONS.USER_GET_MANY],
  },
  {
    key: Paths.PROPERTY.LIST.PATH,
    label: Paths.PROPERTY.LIST.LABEL,
    permissionKey: [
      PERMISSIONS.PROPERTIES_CREATE,
      PERMISSIONS.PROPERTIES_DELETE,
      PERMISSIONS.PROPERTIES_READ,
      PERMISSIONS.PROPERTIES_UPDATE,
    ],
  },
]

export const warehouseItems: MenuItem[] = [
  {
    key: Paths.INVENTORY.LIST.PATH,
    label: Paths.INVENTORY.LIST.LABEL,
    permissionKey: [
      PERMISSIONS.INVENTORIES_CREATE,
      PERMISSIONS.INVENTORIES_DELETE,
      PERMISSIONS.INVENTORIES_READ,
      PERMISSIONS.INVENTORIES_UPDATE,
    ],
  },
  {
    key: Paths.INVENTORYTRANSACTIONS.LIST.PATH,
    label: Paths.INVENTORYTRANSACTIONS.LIST.LABEL,
    permissionKey: [
      PERMISSIONS.INVENTORY_TRANSACTIONS_CREATE,
      PERMISSIONS.INVENTORY_TRANSACTIONS_DELETE,
      PERMISSIONS.INVENTORY_TRANSACTIONS_READ,
      PERMISSIONS.INVENTORY_TRANSACTIONS_UPDATE,
    ],
  },
  {
    key: Paths.INVENTORYCATEGORIES.LIST.PATH,
    label: Paths.INVENTORYCATEGORIES.LIST.LABEL,
    permissionKey: [
      PERMISSIONS.INVENTORY_CATEGORIES_CREATE,
      PERMISSIONS.INVENTORY_CATEGORIES_DELETE,
      PERMISSIONS.INVENTORY_CATEGORIES_READ,
      PERMISSIONS.INVENTORY_CATEGORIES_UPDATE,
    ],
  },
]

export const menuItems: Array<ItemType & { parent?: string; permissionKey?: string[] }> = [
  {
    label: "Dashboard",
    key: "/",
    permissionKey: [
      PERMISSIONS.STATISTIC_CREATE,
      PERMISSIONS.STATISTIC_READ,
      PERMISSIONS.STATISTIC_DELETE,
      PERMISSIONS.STATISTIC_UPDATE,
    ],
  },
  { label: "Quản lí hệ thống", key: "manages", children: commonItems },
  { label: "Cài đặt", key: "settings", children: settingItems },
  { label: "Nhân sự", key: "hrs", children: hrItems },
  { label: "Tài chính", key: "finances", children: financeItems },
  { label: "Quản lí kho", key: "warehouses", children: warehouseItems },
  {
    label: "Đối tác",
    key: "partners",
    permissionKey: [
      PERMISSIONS.PARTNERS_READ,
      PERMISSIONS.PARTNERS_CREATE,
      PERMISSIONS.PARTNERS_DELETE,
      PERMISSIONS.PARTNERS_UPDATE,
    ],
  },
  {
    label: "Khách hàng",
    key: "customers",
    permissionKey: [
      PERMISSIONS.CUSTOMERS_READ,
      PERMISSIONS.CUSTOMERS_CREATE,
      PERMISSIONS.CUSTOMERS_DELETE,
      PERMISSIONS.CUSTOMERS_UPDATE,
    ],
  },

  {
    label: "Logs",
    key: "logs",
    permissionKey: [PERMISSIONS.LOGS_READ, PERMISSIONS.LOGS_CREATE, PERMISSIONS.LOGS_DELETE, PERMISSIONS.LOGS_UPDATE],
  },
  {
    label: "Báo cáo Kinh Doanh",
    key: "finance-report",
    permissionKey: [
      PERMISSIONS.FINANCE_REPORT_READ,
      PERMISSIONS.FINANCE_REPORT_CREATE,
      PERMISSIONS.FINANCE_REPORT_DELETE,
      PERMISSIONS.FINANCE_REPORT_UPDATE,
    ],
  },
]
