import { Paths } from "@/constants"
import { fetchInventory } from "@/modules/warehouses/inventories/services/inventories.services"
import { InventoryType } from "@/modules/warehouses/inventories/types"
import UpsertForm from "@/modules/warehouses/inventory-transactions/view/UpsertForm"
import { useGoBack } from "@/utils/use-go-back"
import { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"

export const Transaction = () => {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const type = searchParams.get("type")
  const [inventoryInit, setInventoryInit] = useState<InventoryType>()
  useEffect(() => {
    async function init() {
      if (id) {
        const response = await fetchInventory(id)
        if (response?.data) {
          setInventoryInit(response.data)
        }
      }
    }
    init()
  }, [id])
  const { goBack } = useGoBack(`${Paths.INVENTORY.LIST.PATH}`)

  const onCancel = () => goBack()
  const onRefreshProps = () => true
  const openProps = true
  const actionType = "CREATE"
  return (
    <UpsertForm
      openProps={openProps}
      cancelProps={onCancel}
      refreshProps={onRefreshProps}
      actionType={actionType}
      inventoryInit={inventoryInit}
      idEdit={""}
      typeInit={type ?? ""}
    />
  )
}
