export const ACCESS_TOKEN = "access-token"
export const IS_AUTHENTICATED = "is-authenticated"
export const USER_INFO = "user-info"
export const PAGINATION_DEFAULT = { limit: 20, offset: 0 }
export const GETLIST_DEFAULT = { isArchived: false }
export const FORMAT_MOMENT = {
  DDMMYYY: "DD-MM-YYYY HH:MM:SS",
}

export const ACTION_METHOD_OBJ = {
  CREATE: "Tạo",
  UPDATE: "Cập nhật",
  READ: "Chi tiết",
  DELETE: "Xóa",
  ARCHIVE: "Lưu trữ",
  UNARCHIVE: "Hủy lưu trữ",
  ERROR: "Có lỗi xảy ra, vui lòng thử lại!",
}

export const PHONE_REGEX = /((^(\+84|84|0|0084){1})(3|5|7|8|9))+([0-9]{8})$/
export const NORMAL_CHARACTER_REGEX = /^([a-zA-Z0-9 _ \@.]+)$/
export const PERMISSIONS = {
  // user
  USER_GET_MANY: "user.get_many",
  USER_GET_ONE: "user.get_one",
  USER_CREATE: "user.create",
  USER_UPDATE: "user.update",
  USER_DELETE: "user.delete",

  // room
  ROOM_GET_MANY: "room.get_many",
  ROOM_GET_ONE: "room.get_one",
  ROOM_CREATE: "room.create",
  ROOM_UPDATE: "room.update",
  ROOM_DELETE: "room.delete",

  // subject
  SUBJECT_GET_MANY: "subject.get_many",
  SUBJECT_GET_ONE: "subject.get_one",
  SUBJECT_CREATE: "subject.create",
  SUBJECT_UPDATE: "subject.update",
  SUBJECT_DELETE: "subject.delete",

  // location
  LOCATION_GET_MANY: "location.get_many",
  LOCATION_GET_ONE: "location.get_one",
  LOCATION_CREATE: "location.create",
  LOCATION_UPDATE: "location.update",
  LOCATION_DELETE: "location.delete",

  // role
  ROLE_READ: "roles_read",
  ROLE_CREATE: "roles_create",
  ROLE_UPDATE: "roles_update",
  ROLE_DELETE: "roles_delete",

  PERMISSION_READ: "permissions_read",
  PERMISSION_CREATE: "permissions_create",
  PERMISSION_UPDATE: "permissions_update",
  PERMISSION_DELETE: "permissions_delete",

  // work time
  WORK_TIME_GET_MANY: "work_time.get_many",
  WORK_TIME_GET_ONE: "work_time.get_one",
  WORK_TIME_CREATE: "work_time.create",
  WORK_TIME_UPDATE: "work_time.update",
  WORK_TIME_DELETE: "work_time.delete",

  PARTNERS_READ: "partners_read",
  PARTNERS_CREATE: "partners_create",
  PARTNERS_UPDATE: "partners_update",
  PARTNERS_DELETE: "partners_delete",

  INVENTORY_TRANSACTIONS_READ: "inventory-transactions_read",
  INVENTORY_TRANSACTIONS_CREATE: "inventory-transactions_create",
  INVENTORY_TRANSACTIONS_UPDATE: "inventory-transactions_update",
  INVENTORY_TRANSACTIONS_DELETE: "inventory-transactions_delete",

  STAFF_READ: "staffs_read",
  STAFF_CREATE: "staffs_create",
  STAFF_UPDATE: "staffs_update",
  STAFF_DELETE: "staffs_delete",

  RECEIVABLE_DEBTS_READ: "receivable-debts_read",
  RECEIVABLE_DEBTS_CREATE: "receivable-debts_create",
  RECEIVABLE_DEBTS_UPDATE: "receivable-debts_update",
  RECEIVABLE_DEBTS_DELETE: "receivable-debts_delete",

  PAYABLE_DEBTS_READ: "payable-debts_read",
  PAYABLE_DEBTS_CREATE: "payable-debts_create",
  PAYABLE_DEBTS_UPDATE: "payable-debts_update",
  PAYABLE_DEBTS_DELETE: "payable-debts_delete",

  PAYSHEETS_READ: "paysheets_read",
  PAYSHEETS_CREATE: "paysheets_create",
  PAYSHEETS_UPDATE: "paysheets_update",
  PAYSHEETS_DELETE: "paysheets_delete",

  USERS_READ: "users_read",
  USERS_CREATE: "users_create",
  USERS_UPDATE: "users_update",
  USERS_DELETE: "users_delete",

  SETTINGS_READ: "settings_read",
  SETTINGS_CREATE: "settings_create",
  SETTINGS_UPDATE: "settings_update",
  SETTINGS_DELETE: "settings_delete",

  CHECK_IN_READ: "check-in_read",
  CHECK_IN_CREATE: "check-in_create",
  CHECK_IN_UPDATE: "check-in_update",
  CHECK_IN_DELETE: "check-in_delete",

  PROPERTIES_READ: "properties_read",
  PROPERTIES_CREATE: "properties_create",
  PROPERTIES_UPDATE: "properties_update",
  PROPERTIES_DELETE: "properties_delete",

  LOGS_READ: "logs_read",
  LOGS_CREATE: "logs_create",
  LOGS_UPDATE: "logs_update",
  LOGS_DELETE: "logs_delete",

  FINANCE_REPORT_READ: "finance-report_read",
  FINANCE_REPORT_CREATE: "finance-report_create",
  FINANCE_REPORT_UPDATE: "finance-report_update",
  FINANCE_REPORT_DELETE: "finance-report_delete",

  INVENTORIES_READ: "inventories_read",
  INVENTORIES_CREATE: "inventories_create",
  INVENTORIES_UPDATE: "inventories_update",
  INVENTORIES_DELETE: "inventories_delete",

  INTERNAL_DEBTS_READ: "internal-debts_read",
  INTERNAL_DEBTS_CREATE: "internal-debts_create",
  INTERNAL_DEBTS_UPDATE: "internal-debts_update",
  INTERNAL_DEBTS_DELETE: "internal-debts_delete",

  RECEIPTS_READ: "receipts_read",
  RECEIPTS_CREATE: "receipts_create",
  RECEIPTS_UPDATE: "receipts_update",
  RECEIPTS_DELETE: "receipts_delete",

  CUSTOMERS_READ: "customers_read",
  CUSTOMERS_CREATE: "customers_create",
  CUSTOMERS_UPDATE: "customers_update",
  CUSTOMERS_DELETE: "customers_delete",

  INVENTORY_CATEGORIES_READ: "inventory-categories_read",
  INVENTORY_CATEGORIES_CREATE: "inventory-categories_create",
  INVENTORY_CATEGORIES_UPDATE: "inventory-categories_update",
  INVENTORY_CATEGORIES_DELETE: "inventory-categories_delete",

  INVENTORY_READ: "inventories_create",
  INVENTORY_CREATE: "inventories_create",
  INVENTORY_UPDATE: "inventories_update",
  INVENTORY_DELETE: "inventories_delete",

  STATISTIC_CREATE: "statistic_create",
  STATISTIC_READ: "statistic_read",
  STATISTIC_UPDATE: "statistic_update",
  STATISTIC_DELETE: "statistic_delete",
}

export const DATE_OF_WEEK_OPTIONS = [
  {
    id: "",
    value: "",
    label: "Chọn",
    text: "Chọn",
  },
  {
    id: "monday",
    value: "monday",
    label: "Thứ 2",
    text: "Thứ 2",
  },
  {
    id: "tuesday",
    value: "tuesday",
    label: "Thứ 3",
    text: "Thứ 3",
  },
  {
    id: "wednesday",
    value: "wednesday",
    label: "Thứ 4",
    text: "Thứ 4",
  },
  {
    id: "thursday",
    value: "thursday",
    label: "Thứ 5",
    text: "Thứ 5",
  },
  {
    id: "friday",
    value: "friday",
    label: "Thứ 6",
    text: "Thứ 6",
  },
  {
    id: "saturday",
    value: "saturday",
    label: "Thứ 7",
    text: "Thứ 7",
  },
  {
    id: "sunday",
    value: "sunday",
    label: "Chủ nhật",
    text: "Chủ nhật",
  },
]

export const WORK_TIME_STATUS_OPTIONS = [
  {
    id: "",
    value: "",
    label: "Chọn",
  },
  {
    id: "INIT",
    value: "INIT",
    label: "Khởi tạo",
  },
  {
    id: "ACTIVE",
    value: "ACTIVE",
    label: "Đã xử lý",
  },
  {
    id: "CANCELED",
    value: "CANCELED",
    label: "Đã huỷ",
  },
]
